const INFO = {
	main: {
		title: "Beauty by Anna",
		name: "Anna",
		email: "anna@beauty-by-anna.com",
		logo: "/logo.png",
	},

	socials: {
		instagram: "https://www.instagram.com/annaveret_hair",
		vagaro: "https://l.instagram.com/?u=https%3A%2F%2Fmysite.vagaro.com%2Fbeautybymkp%2Fservices&e=AT1Vn6Oxj94nKQcPHD5SZDfS14iTzy4bMLHrOpLlZ6NUSD_MJOWQDSiUlV7lIxM5igJ0nhwlnUReWpLxcyKZ0xl-1-lJBLJa",
		website: "https://beauty-by-anna.com"
	},

	homepage: {
		title: "Discover Expert Hair Services in the Heart of Falls Church with Beauty by Anna",
		description:
			"Welcome to our salon, conveniently located in Falls Church, VA. At Beauty by Anna, we specialize in vibrant hair coloring, with a focus on exquisite balayage and radiant highlights. Our expert stylists work to enhance your natural beauty with personalized color transformations that leave you glowing."
	},

	services: [
		{
			title: "Women Haircuts",
			description: "Enjoy a personalized, warm haircut experience tailored just for you! We blend classic charm with a fresh, modern twist so you can feel confident and uniquely yourself.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View",
			link: "https://www.instagram.com/p/Cz6VaslutcQ/",
			photo: "/photo1.jpeg"
		},
		{
			title: "Men Haircuts",
			description: "Experience a warm and professional haircut where your style meets expert precision. Our friendly team crafts a look that's uniquely yours, ensuring you leave feeling confident and refreshed!",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View",
			link: "https://www.instagram.com/reel/CypbepKtkUY/?igsh=M3d4M3FscTRyMjJ6",
			photo: "/photo2.jpeg"
		},
		{
			title: "Kids Haircuts",
			description: "Discover a fun and friendly haircare experience for kids, where creativity meets comfort! Our talented team ensures that every haircut is playful, relaxed, and tailored to bring out your child's unique style.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View",
			link: "https://www.instagram.com/s/aGlnaGxpZ2h0OjE3ODg0Nzg3NTMwNzM0OTQ3?story_media_id=2490011150026837997&igsh=MWpiMzhpMDd6NGNzaw==",
			photo: "/photo3.jpeg"
		},
		{
			title: "Highlights",
			description: "Enhance your look with expertly crafted highlights that add dimension and a natural glow. Our skilled stylists deliver precision techniques for a subtle or bold effect tailored to your unique style.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View",
			link: "https://www.instagram.com/p/Cy1ROcLxvJ4/",
			photo: "/photo4.jpeg"
		},
		{
			title: "Hair and Scalp Treatments",
			description:
				"Give your hair and scalp some extra love! Enjoy personalized care with our gentle treatments that leave you feeling refreshed, vibrant, and completely pampered.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View",
			link: "https://www.instagram.com/stories/highlights/17858838716479882/",
			photo: "/photo5.jpeg"
		},
		{
			title: "Balayage",
			description:
				"Experience the art of balayage, where hand-painted techniques create a natural, sun-kissed glow. Our expert stylists tailor each look to enhance your unique features and bring out your inner radiance.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View",
			link: "https://www.instagram.com/reel/CqdhVIhsGQv/?igsh=NGlxaHQ0bXVncDV5",
			photo: "/photo6.jpeg"
		}
	],
};

export default INFO;
