import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Footer from "../components/common/footer";
import Works from "../components/homepage/works";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/homepage.css";
import {faCalendarDays} from "@fortawesome/free-regular-svg-icons";

const Homepage = () => {
    const [stayLogo, setStayLogo] = useState(false);
    const [logoSize, setLogoSize] = useState(80);
    const [oldLogoSize, setOldLogoSize] = useState(80);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            let scroll = Math.round(window.pageYOffset, 2);

            let newLogoSize = 80 - (scroll * 4) / 10;

            if (newLogoSize < oldLogoSize) {
                if (newLogoSize > 40) {
                    setLogoSize(newLogoSize);
                    setOldLogoSize(newLogoSize);
                    setStayLogo(false);
                } else {
                    setStayLogo(true);
                }
            } else {
                setLogoSize(newLogoSize);
                setStayLogo(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [logoSize, oldLogoSize]);

    const currentSEO = SEO.find((item) => item.page === "home");

    const logoStyle = {
        display: "flex",
        position: stayLogo ? "fixed" : "relative",
        top: stayLogo ? "3vh" : "auto",
        zIndex: 999,
        border: stayLogo ? "1px solid white" : "none",
        borderRadius: stayLogo ? "50%" : "none",
        boxShadow: stayLogo ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : "none",
        transform: `scale(${logoSize / 80})`,
        transformOrigin: "top center",
        willChange: "transform",
        transition: "transform 0.2s ease-out"
    };

    const worksData = {
        address: "7121 Leesburg Pike Suite, Falls Church, VA 22043"
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{INFO.main.title}</title>
                <meta name="description" content={currentSEO.description}/>
                <meta
                    name="keywords"
                    content={currentSEO.keywords.join(", ")}
                />
            </Helmet>

            <div className="page-content">
                <div className="content-wrapper">
                    <div className="homepage-container">
                        <div className="page-heading">
                            <h1>Beauty By Anna</h1>
                            <div className="heading-subtitle">
                                7121 Leesburg Pike Suite
                            </div>
                            <div className="heading-subtitle">
                                Falls Church, VA 22043
                            </div>
                        </div>

                        <div className="homepage-first-area">
                            <div className="homepage-first-area-left-side">
                                <h2 className="homepage-title">{INFO.homepage.title}</h2>
                                <p className="homepage-subtitle">{INFO.homepage.description}</p>
                            </div>

                            <div className="homepage-first-area-right-side">
                                <div className="homepage-image-container">
                                    <div className="homepage-image-wrapper">
                                        <img
                                            src="/homepage.jpg"
                                            alt="Anna providing hairdressing services"
                                            className="homepage-image"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="homepage-socials">
                            <a
                                href={INFO.socials.instagram}
                                target="_blank"
                                rel="noreferrer noopener"
                                aria-label="Book an appointment on Instagram">
                                Book appointment <FontAwesomeIcon
                                icon={faCalendarDays}
                                className="homepage-social-icon"
                                aria-hidden="true"
                            />
                            </a>
                        </div>
                        <div className="homepage-projects">
                            <div className="section-title">
                                <h2>Services</h2>
                                <p className="section-subtitle">Explore range of professional hair services</p>
                            </div>
                            <AllProjects/>
                        </div>

                        <div className="homepage-after-title">
                            <div className="homepage-works">
                                <Works {...worksData} />
                            </div>
                        </div>

                        <div className="page-footer">
                            <Footer/>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Homepage;
