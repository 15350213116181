import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

import Homepage from "./pages/homepage";
import Notfound from "./pages/404";

import { TRACKING_ID } from "./data/tracking";
import "./app.css";

function App() {
	useEffect(() => {
		if (TRACKING_ID) {
			ReactGA.initialize(TRACKING_ID);
			ReactGA.send("pageview");
		}
	}, []);

	return (
		<div className="App">
			<Helmet>
				<html lang="en" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Helmet>
			<Routes>
				<Route path="/" element={<Homepage />} />
				<Route path="*" element={<Notfound />} />
			</Routes>
		</div>
	);
}

export default App;
