const SEO = [
    {
        page: "home",
        description:
            "Expert hair salon in Falls Church, VA. Specializing in balayage, highlights, color correction, and precision haircuts. Master colorist Anna creates stunning, natural-looking balayage and dimensional highlights. Book your appointment today!",
        keywords: [
            "balayage Falls Church VA",
            "balayage McLean VA",
            "balayage Vienna VA",
            "balayage Tysons Corner",
            "balayage Arlington VA",
            "balayage Reston VA",
            "highlights specialist VA",
            "best balayage Northern VA",
            "hair color correction Falls Church",
            "hair color correction McLean",
            "hair color correction Tysons",
            "natural balayage specialist",
            "dimensional highlights VA",
            "blonde balayage expert",
            "foil highlights Northern VA",
            "hair color specialist Vienna",
            "Asian hair balayage Tysons",
            "Beauty By Anna salon",
            "Leesburg Pike hair salon",
            "hair transformation McLean",
            "blonde specialist Arlington",
            "balayage and highlights Reston",
            "affordable luxury salon Tysons",
            "experienced colorist VA",
            "hair painting expert",
            "beauty salon near me",
            "top rated hair salon VA",
            "best hair salon McLean",
            "premium salon Tysons Corner",
        ],
    },
    {
        page: "about",
        description:
            "Meet Anna, an award-winning hair stylist and balayage specialist in Falls Church, VA. Expert in creating custom balayage, highlights, and color corrections. Specialized in Asian hair coloring techniques for natural-looking results.",
        keywords: [
            "balayage specialist Anna",
            "professional colorist Falls Church",
            "Asian hair balayage expert",
            "highlights specialist VA",
            "color correction expert",
            "balayage master stylist",
            "hair painting specialist",
            "Beauty By Anna founder",
            "top hair colorist VA",
            "certified balayage expert"
        ],
    },
    {
        page: "projects",
        description:
            "Browse our stunning hair transformations in Tysons & Falls Church, VA. Specializing in balayage, highlights, color correction, precision cuts, and luxury treatments. View our before & after gallery.",
        keywords: [
            "hair transformation gallery",
            "before and after hair color",
            "balayage results Tysons",
            "hair color transformation",
            "best haircuts Falls Church",
            "premium hair services VA",
            "hair makeover specialist",
            "blonde specialist Tysons",
            "Asian hair transformation",
            "wedding hair specialist VA"
        ],
    },
    {
        page: "articles",
        description:
            "Expert hair care advice, styling tutorials, and trend updates from Beauty By Anna. Learn professional tips for maintaining healthy, beautiful hair between salon visits.",
        keywords: [
            "professional hair care tips",
            "hair maintenance guide",
            "hair color maintenance",
            "salon styling tutorials",
            "hair treatment advice",
            "hair care blog Tysons",
            "hair trend updates",
            "hair health tips",
            "color protection advice",
            "styling tips for Asian hair"
        ],
    },
    {
        page: "contact",
        description:
            "Visit Beauty By Anna at 7121 Leesburg Pike Suite, Falls Church, VA 22043. Schedule your balayage, highlights, or color consultation. Book through Instagram @annaveret_hair for professional hair coloring services.",
        keywords: [
            "book balayage Falls Church",
            "highlights appointment VA",
            "hair color consultation",
            "Beauty By Anna location",
            "balayage consultation booking",
            "professional hair coloring",
            "salon appointment Falls Church",
            "hair color specialist booking",
            "balayage near me",
            "highlights consultation VA"
        ],
    },
];

export default SEO;
