import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import "./styles/works.css";

const Works = ({ address }) => {
    return (
        <div className="works-body">
            <div className="location-card">
                <div className="location-icon">
                    <FontAwesomeIcon icon={faMapMarkerAlt} alt="Location marker icon"/>
                </div>
                <div className="location-details">
                    <h3>Location</h3>
                    <p>{address}</p>
                    <a 
                        href="https://maps.google.com/?q=7121+Leesburg+Pike+Suite,+Falls+Church,+VA+22043" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="directions-link"
                    >
                        Get Directions
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Works;
